import React from "react";
import marioWalkingGif from "../pictures/marioWalkingGif.gif"

const TextWithGif = () => {
    return (
        <>
            <div className="container TextWithGifContainer">
                <div className="row">
                    <div className="col-12 ">
                        <div className="row"><h1 className="text-center marioFont text-white">Our game</h1></div>
                        <div className="row text-center marioFont text-white"><p>Step into the wild world of Mario69, where classic platforming meets chaotic multiplayer action!

                            Embark on a journey through levels inspired by the very first Mario game, re-engineered for an exhilarating multiplayer experience. Whether you're hosting a server or hopping into an existing one, race to be the first to the finish line in our nostalgically crafted platformer world. But the challenge doesn't stop there!

                            As soon as you claim victory at the finish line, the environment shifts dramatically. You are instantly transported into a high-stakes First-Person Shooter arena where the ultimate goal is to capture the flag. Hold your ground in the victory circle for a tense five seconds to secure your win, all while dodging a flurry of enemy attacks!

                            If you meet your demise in the FPS arena, no worries—you’ll be teleported back to the start of the Mario-themed stage, giving you another shot at platforming prowess and FPS dominance.</p></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TextWithGif;