import React from "react";
import { Link, useLocation } from "react-router-dom";
import MarioTextInColor from "./MarioTextInColor";
import navbar from '../styling/navbar.css';

const Navbar = () => {
  const location = useLocation();

  const handleNavLinkClick = () => {
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="https://i.gifer.com/origin/99/999b2183c19ca9288a6995c4d7bb0b3e_w200.gif" alt="" width="30" height="24" className="d-inline-block align-text-top" />
            <a className="text-decoration-none text-white" href="/">{<MarioTextInColor/>}</a>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to="/" onClick={handleNavLinkClick}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === "/servers" ? "active" : ""}`} to="/servers" onClick={handleNavLinkClick}>Servers</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === "/downloads" ? "active" : ""}`} to="/downloads" onClick={handleNavLinkClick}>Download</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
