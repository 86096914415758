import React from "react";
import '../styling/MarioTextInColor.css'

const MarioTextInColor = () => {
    return (
        <>
            <span>M</span><span>a</span><span>r</span><span>i</span><span>o</span>69
        </>
    )
}

export default MarioTextInColor;