import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styling/ServerList.css'

const ServerList = () => {
  const [lobbys, setLobbys] = useState([]);
  let storedLobbys;

  useEffect(() => {
    const socket = io('https://mario69.nl:4000');

    socket.on("lobbys", (lobbys) => {
      if (storedLobbys && arraysEqual(storedLobbys, lobbys)) {
      } else {
        storedLobbys = lobbys;
        setLobbys(lobbys);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const drawLobbys = (lobbys) => {
    return lobbys.map((lobby) => (

      <div className="col-12 col-md-3 mb-3 " key={lobby.id}>
        <div className="card serverListCard bg-light text-dark">
          <div className="card-body">
            <h3>{lobby.lobbyName}</h3>
            <p><i className="bi bi-hdd-network-fill"></i> Owner: {lobby.lobbyOwner}</p>
            <p><i className="bi bi-person-fill"></i> Players: {lobby.amountOfPlayers === null ? <i class="bi bi-person-dash-fill"></i> : lobby.amountOfPlayers}</p>
            <p>Status: {
              lobby.status === 1 ? <i class="bi bi-circle-fill text-warning">About to start</i> :
                lobby.status === 2 ? <i class="bi bi-circle-fill text-success">Started</i> :
                  lobby.status === 3 ? <i class="bi bi-circle-fill text-danger">Ended</i> :
                    "unknown"
            }</p>

          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        {lobbys.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
              <span className="visually-hidden">If this takes to long it could mean that there are no servers, or your connection is faulty. </span>
            </div>
          </div>
        ) : (
          drawLobbys(lobbys)
        )}
      </div>
    </div>
  );
};

export default ServerList;
