import React from "react";

const PageNotFound = () => {
    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src="https://i.pinimg.com/originals/1e/8a/0c/1e8a0c70035899417bddb5dd044794df.gif" class="img-fluid" alt="..."></img>
                    </div>
                    <div className="col-12 text-center">
                        <p className="display-2 font-weight-bold">Ooops....</p>
                        <p className="display-4">That page does not exist</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;