import React from 'react';
import AppleLogo from '../pictures/appleLogo.png';
import WindowsLogo from '../pictures/windowsLogo.png';
import '../styling/download.css';

const Download = () => {

  const handleDownload = (fileUrl, fileName) => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <section className="download-cards">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="download-card">
                <img src={WindowsLogo} alt="Windows Icon" />
                <h2 className='marioFont'>Windows Version</h2>
                <p className='marioFont'>Download the latest version for Windows.</p>
                <button 
                  className="btn btn-primary"
                  onClick={() => handleDownload('../Mario69_Installer.exe', 'Mario69_Installer.exe')}
                >
                  Download
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="download-card">
                <img src={AppleLogo} alt="Mac Icon" />
                <h2 className='marioFont'>Mac Version</h2>
                <p className='marioFont'>Download the latest version for MacOS.</p>
                <button 
                  className="btn btn-primary"
                  onClick={() => handleDownload('../Mario69.dmg', 'Mario69.dmg')}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
