import React from "react";
import '../styling/MarioLogoWithText.css'
import JumpingMario from "../pictures/JumpingMario.png"


const MarioLogoWithText = () => {
    return (
        <>
            <div className="content mt-md-5">
                <div className="welcome-text">Welcome</div>
                <div className="left-text">Mario</div>
                <div className="right-text">69</div>
                <div className="mario-container">
                    <img src={JumpingMario} alt="Mario" width="200"/>
                </div>
            </div>
        </>
    )
}

export default MarioLogoWithText;
