import React from 'react';
import TextWithGif from '../components/TextWithGif';
import '../styling/index.css'
import MarioLogoWithText from '../components/MarioLogoWithText';
import MarioTextInColor from '../components/MarioTextInColor'

const Index = () => {
  return (
    <div>
      <main>
      <MarioLogoWithText />
      <h1 className='text-center marioFont marioInColorComponent'><MarioTextInColor /></h1>
        <div className='mt-5'>
        <TextWithGif/>
        </div>
      </main>
    </div>
  );
};

export default Index;
