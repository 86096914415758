import React from 'react';
import ServerList from '../components/ServerList'
import MarioTextInColor from '../components/MarioTextInColor';

const Servers = () => {
  return (
    <div>
      <main>
        <div className='container mt-5 mb-3'>
          <div className='row'>
            <div className='col-1'></div>
            <div className='col-10 whiteHeaderDiv'>
              <h1 className='marioFont'>Current {<MarioTextInColor/>} lobby's</h1>
            </div>
            <div className='col-1'></div>
          </div>
        </div>
        <p className='text-center marioFont mb-5'>Join a server, have a look at our <a href='/downloads'>downloads</a> page</p>
        <ServerList />
      </main>
    </div>
  );
};

export default Servers;
